import * as Slideshow from './slideshow.js';
import * as Parallax from './parallax.js';
import * as Nav from './nav.js';
import * as ImagesLoaded from '../../../../vendor/gray_matter/base/app/assets/javascript/utility/images_loaded.js'

export async function init(){
  await ImagesLoaded.waitForAll(document.body);

  afterImagesLoaded();
}

function afterImagesLoaded(){
  if (document.body.classList.contains('project_index')){
    Parallax.update();
    Nav.update();
  }
  if (document.body.classList.contains('project') || document.body.classList.contains('research_post')){
    Slideshow.init();
  }
}
