import axios from 'axios';

let mentorship_form;
let form_back;
let form_next;
let clear_form;
let submit_form;
let sections;
let sections_n;
let current_section;
let mentorship_form_container;

export function init(){
  mentorship_form = document.querySelector('#mentorship_form form');
  if (!mentorship_form) return;

  mentorship_form_container = document.getElementById('mentorship_form');
  sections = document.querySelectorAll('.form_section');
  sections_n = sections.length;
  current_section = 0;
  form_back = document.getElementById('form_back');
  form_next = document.getElementById('form_next');
  clear_form = document.getElementById('clear_form');
  submit_form = document.getElementById('submit_form');
  form_back.addEventListener('click', back);
  form_next.addEventListener('click', next);
  clear_form.addEventListener('click', clear);
  submit_form.addEventListener('click', submitForm);
  mentorship_form.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"').forEach((e) => {
    e.addEventListener('input', selectOther);
    e.addEventListener('focus', prefillOtherInput);
  });
  mentorship_form.querySelectorAll('.radio.other input[type="radio"], .checkbox.other input[type="checkbox"]').forEach((e) => {
    e.closest('.form_field').addEventListener('change', syncOtherInput);
  });
  showSection();
}

function clear(){
  mentorship_form.querySelectorAll('input[type="text"]').forEach((e) => e.value = '');
  mentorship_form.querySelectorAll('input[type="radio"], input[type="checkbox"]').forEach((e) => e.checked = false);
  current_section = 0;
  showSection();
}

async function submitForm(e){
  checkRequired();
  if (missingRequired()){
    return;
  }

  disableBlankOthers();
  var data = new FormData(mentorship_form);
  try{
    const response = await axios.post(mentorship_form.getAttribute('action'), data);
    mentorship_form.innerHTML = '<div id="mentorship_form_submission_message">' + response.data.message + '</div>';
    document.getElementById('form_controls').remove();
    document.getElementById('mentorship_form').scrollIntoView();
  } catch(error){

  }

}

function missingRequired(){
  var first_missing = mentorship_form.querySelector('.required.missing');
  if (first_missing){
    first_missing.scrollIntoView();
    setTimeout(() => document.querySelector('header').classList.add('hide'), 100);
    return true;
  } else {
    return false;
  }
}

function prefillOtherInput(){
  if (this.user_value){
    this.value = this.user_value;
    selectOther.call(this);
  }
}

function disableBlankOthers(){
  mentorship_form.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"]').forEach((e) => {
    if (e.value.trim().length <= 0){
      e.disabled = true;
    }
  });
}

function enableOthers(){
  mentorship_form.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"]').forEach((e) => {
    e.disabled = false;
  });
}

function syncOtherInput(){
  if (!this.querySelector('.other input[type="radio"], .other input[type="checkbox"]').checked){
    var text_input = this.querySelector('.other input[type="text"]');
    text_input.user_value = text_input.value;
    text_input.value = '';
  }
}

function selectOther(){
  var form_option = this.closest('.form_option');
  form_option.querySelector('input[type="checkbox"], input[type="radio"]').checked = this.value.trim().length > 0;
}

function next(){
  checkRequired();
  if (missingRequired()){
    return;
  }
  current_section += 1;
  if (current_section > sections_n - 1){
    current_section = sections_n - 1;
  }
  showSection();
  scrollIntoForm();
}

function scrollIntoForm(){
  mentorship_form_container.scrollIntoView();
}

function checkRequired(){
  document.querySelectorAll('.form_section.current .form_field.required').forEach(checkRequiredField);
}

function checkRequiredField(e){
  switch(e.dataset.type){
    case 'text_input':
      checkMissingTextInput(e);
    break;
    case 'radio_group':
      checkMissingGroup(e);
    break;
    case 'checkbox_group':
      checkMissingGroup(e);
    break;
    case 'appointment':
      checkMissingAppointment(e);
    break;
  }
}

function checkMissingTextInput(e){
  if (e.querySelector('input[type="text"]').value.trim().length == 0){
    e.classList.add('missing');
  } else {
    e.classList.remove('missing');
  }
}

function checkMissingAppointment(e){
  if (e.querySelectorAll('input[type="checkbox"]:checked').length == 0){
    e.classList.add('missing');
  } else {
    e.classList.remove('missing');
  } 
}

function checkMissingGroup(e){
  var other = e.querySelector('.form_option.other');
  if (other && other.value.trim().length > 0){
    e.classList.remove('missing');
    return;
  }

  if (e.querySelectorAll('input[type="radio"]:checked').length == 0){
    e.classList.add('missing');
  } else {
    e.classList.remove('missing');
  }
}

function back(){
  current_section -= 1;
  if (current_section < 0){
    current_section = 0;
  }
  showSection();
  scrollIntoForm();
}

function showSection(){
  document.querySelectorAll('.form_section.current').forEach((e) => e.classList.remove('current'));
  sections[current_section].classList.add('current');

  if (sections_n > 1){
    if (current_section == sections_n - 1){
      submit_form.classList.add('show');
    } else {
      submit_form.classList.remove('show');
    }

    if (current_section < sections_n - 1){
      form_next.classList.add('show');
    } else {
      form_next.classList.remove('show');
    }

    if (current_section > 0){
      form_back.classList.add('show');
    } else {
      form_back.classList.remove('show');
    }
  } else {
    submit_form.classList.add('show');
  }
}