import * as Parallax from './parallax.js';

var html;
export var header;
export var nav;
export var lever_word_mark;
export var nav_background;
export var mobile_hamburger;
export var last_scroll_position;

export function init(){
  html = document.querySelector('html');
  header = document.querySelector('header');
  nav = document.querySelector('nav');
  lever_word_mark = document.getElementById('lever_word_mark');
  nav_background = document.querySelector('.nav_background');
  mobile_hamburger = document.getElementById('mobile_hamburger_wrap');
  mobile_hamburger.addEventListener('click', toggleMobileNav);
  window.addEventListener('scroll', update);
  window.addEventListener('resize', update);
}

function genericHeaderToggle(){
  var scroll_position = html.scrollTop;
  if (scroll_position <= 10){
    header.classList.remove('hide');
    header.classList.add('top');
    nav.classList.remove('scrolling', 'show_nav');
    header.classList.remove('show_nav');
    lever_word_mark.classList.remove('show');
    nav_background.classList.remove('show');
  } else if (scroll_position >= last_scroll_position){
    header.classList.add('hide');
    header.classList.remove('top');
  } else {
    header.classList.remove('hide', 'top');
    nav.classList.add('scrolling');
    lever_word_mark.classList.add('show');
    nav_background.classList.add('show');
  }
}

function toggleMobileNav(){
  document.body.classList.toggle('show_mobile_nav');
}

export function update(){
  if (document.body.classList.contains('landing')) return;

  genericHeaderToggle();
  last_scroll_position = html.scrollTop;
}

