import * as ImagesLoaded from '../../../../vendor/gray_matter/base/app/assets/javascript/utility/images_loaded.js'

var images;
var landing;
var landing_logo;
var current_image;

export async function init(){
  landing = document.getElementById('landing');
  if (window.innerWidth <= 768){
    if (landing) landing.remove();
    landing = false;
  }

  if (!landing){
    setupNavTransition();
    return;
  } 
  
  current_image = 0;
  
  await ImagesLoaded.waitForAll(landing);
  images = document.querySelectorAll('.landing_image');
  landing_logo = document.getElementById('landing_logo');
  landing.addEventListener('transitionend', finalTransition);
  landing_logo.addEventListener('transitionend', afterImagesLoaded);
  document.body.classList.add('landing_start');
  document.body.addEventListener('click', stopLanding);
  document.body.classList.add('landing');
  setupNavTransition(); // setup after landing class so as not to trigger animation
}

function setupNavTransition(){
  var nav_link_ul = document.getElementById('nav_link_ul');
  if (nav_link_ul){
    nav_link_ul.offsetHeight;
    nav_link_ul.classList.add('transitions');
  }
}

function stopLanding(e){
  if (e.target.closest('nav')) return;
  landing.classList.add('end');
}

function finalTransition(e){
  if (e.target.getAttribute('id') == 'landing_logo') return;
  
  if (e.propertyName == 'opacity'){
    landing.remove();
    document.body.classList.remove('landing', 'landing_start');
  }
  document.body.removeEventListener('click', stopLanding);
}


export function afterImagesLoaded(){
  images.forEach((e) => {
    e.addEventListener('transitionend', advanceStep);
    e.dataset.step = 1;
  });
  showCurrentImage()
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function advanceStep(e){
  if (current_image == 2){
    current_image = 3;
    showCurrentImage();
    return;
  }

  if (current_image == 3){
    await sleep(800);
    landing.classList.add('with_logo');
    document.getElementById('landing_logo').remove();
    images[0].classList.remove('active');
    images[1].classList.remove('active');
    images[2].classList.add('step2');
    images[3].classList.add('step2');
    current_image = 4;
    return;
  }

  if (current_image == 4){
    landing.classList.add('end');
    return;
  }

  this.dataset.step = this.dataset.step * 1 + 1;
  if (this.dataset.step * 1 < 3){
    this.classList.add('step' + this.dataset.step);
  } else {
    current_image += 1;
    await sleep(800);
    showCurrentImage();
  }
}

function showCurrentImage(){
  var image = images[current_image];
  image.classList.add('transition' + current_image);
  image.offsetHeight;
  image.classList.add('active');
  image.offsetHeight;
  image.classList.add('step1');
  image.offsetHeight;
}