import axios from 'axios';
import * as Projects from './site/projects.js';
import * as Parallax from './site/parallax.js';
import * as Nav from './site/nav.js';
import * as Images from './site/images.js';
import * as Homepage from './site/homepage.js';
import * as Form from './site/form.js';

function init(){
  document.addEventListener('turbolinks:load', pageInit);
  document.addEventListener('turbolinks:before-cache', cleanup);
  axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector("[name='csrf-token']").content;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Accept'] = 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript';
}

function pageInit(){
  Parallax.init();
  Projects.init();
  Nav.init();
  Images.init();
  Homepage.init();
  Form.init();
  document.querySelectorAll('.toggle_button').forEach((e) => e.addEventListener('click', toggleExpand));
  document.querySelectorAll('.expanded_content').forEach((e) => e.addEventListener('transitionend', clearHeight));
  var news_indexes = document.getElementById('news_indexes');
  if (news_indexes){
    news_indexes.addEventListener('click', viewMore);
  }
}

async function viewMore(e){
  if (!e.target.classList.contains('view_more_link')) return;

  const response = await axios.get(e.target.dataset.url);
  let view_more_container = e.target.closest('.view_more_container');
  let view_more_items = view_more_container.querySelector('.view_items');
  let view_more_link_container = view_more_container.querySelector('.view_more_link_container');
  view_more_items.innerHTML = view_more_items.innerHTML + response.data.view;
  view_more_link_container.innerHTML = response.data.link;
}

function clearHeight(e){
  e.stopPropagation();
  e.preventDefault();
  this.style.height = '';
  this.transitioning = false;
}

function toggleExpand(){
  let parent = this.closest('.expanded_parent');
  let content = parent.querySelector('.expanded_content');
  if (content.transitioning) return;

  parent.classList.toggle('expanded');
  content.classList.add('pretransition');
  content.transitioning = true;
  let height;
  if (parent.classList.contains('expanded')){
    height = content.offsetHeight;
    content.style.height = 0;
    content.offsetHeight;
  } else {
    height = content.offsetHeight;
    content.style.height = height + 'px';
    content.offsetHeight;
    height = 0;
  }
  content.style.height = height + 'px';
  content.classList.remove('pretransition');
}

function cleanup(){
  document.body.classList.remove('show_mobile_nav');
  Nav.nav.classList.remove('scrolling');
  Nav.nav.classList.remove('show_nav');
  Nav.header.classList.remove('show_nav');
  Nav.lever_word_mark.classList.remove('show');
  Nav.nav_background.classList.remove('show');
  
  if (!document.body.classList.contains('homepage')){
    Nav.header.classList.add('top');
  }

  if (document.body.classList.contains('project_index')){
    document.getElementById('projects_index_cover').classList.remove('hide');
    Projects.projects_index.classList.remove('show');
  }
}

document.addEventListener('DOMContentLoaded', function(){
  init();
});