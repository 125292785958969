import * as Nav from './nav.js'

var parallax_elements;

export function init(){
  parallax_elements = document.querySelectorAll('.parallax');
  window.addEventListener('scroll', update);
  window.addEventListener('resize', update);
}

export function update(){
  var wh = window.innerHeight * 0.55
  var scale = window.innerWidth >= 800 ? 1.02 : 1.005;
  
  parallax_elements.forEach((e, i) => {
    var element_offset = (e.getBoundingClientRect().top - wh) / wh;
  
    if (element_offset <= 0 || e.dataset.type){
      e.style.transform = 'translateY(0)';
      e.style.opacity = 1;
    } else if ((1 - (element_offset / scale)) >= 0){
      e.style.transform = 'translateY(' + 4 * element_offset + 'rem)';
      e.style.opacity = 1 - (element_offset / scale);
    } else {
      e.style.opacity = 0;
    }
  });
}