import GMSlideshow from '../../../../vendor/gray_matter/base/app/assets/javascript/front_end/slideshow.js'
import * as Projects from './projects.js';

var modal_slideshow;
var project_slideshow;
var project_slideshow_container;
var lock;
var start_x;
var delta;
var relative_delta;
var swipe_relative_scale;
var swipe_scale;

export function init(){
  project_slideshow = document.getElementById('project_slideshow'); 
  lock = false;
  if (project_slideshow){
    project_slideshow.classList.add('no_transition');
  }
  GMSlideshow.init(document);
  loadAsync();

  if (project_slideshow){
    project_slideshow_container = document.getElementById('project_slideshow_container');
    document.querySelectorAll('.slideshow_navigation.right').forEach((e) => e.addEventListener('click', next));
    document.querySelectorAll('.slideshow_navigation.left').forEach((e) => e.addEventListener('click', previous));
    var s = 100 / project_slideshow.getBoundingClientRect().width;
    var x = 0;
    project_slideshow.querySelectorAll('.slide').forEach((e) => {
      var ew = e.getBoundingClientRect().width;
      e.dataset.x = (s * (x - ew * 0.5)).toPrecision(4);
      x -= ew;
    });
    project_slideshow.addEventListener('gm:slideset', updatePosition);
    project_slideshow.addEventListener('transitionend', normalizePosition);
    project_slideshow.gm.current = 2;
    updatePosition();
    lock = false;
    project_slideshow.offsetHeight;
    project_slideshow.classList.remove('fresh', 'no_transition');
    project_slideshow.addEventListener('touchstart', startSwipe);
    project_slideshow.addEventListener('touchmove', swipe);
    project_slideshow.addEventListener('touchend', endSwipe);
  }
  
  modal_slideshow = document.getElementById('modal_slideshow');
  if (modal_slideshow){
    document.getElementById('modal_slideshow_close').addEventListener('click', close);
    document.getElementById('modal_slideshow_left').addEventListener('click', modalPrevious);
    document.getElementById('modal_slideshow_right').addEventListener('click', modalNext);
    document.addEventListener('keydown', documentKeyHandler);
  }
  document.querySelectorAll('.image_cell').forEach((e) => e.addEventListener('click', openModalSlideshow));
  // document.querySelectorAll('.project_slideshow').swipe({ swipe: Site.exploreSwipeHandler, allowPageScroll: 'none', threshold: 5}); 
}

function startSwipe(e){
  e.preventDefault();
  e.stopPropagation();
  start_x = e.touches[0].pageX;
  swipe_scale = 100 / project_slideshow.getBoundingClientRect().width;
  swipe_relative_scale = 100 /  project_slideshow_container.getBoundingClientRect().width;
  project_slideshow.classList.add('no_transition');
  project_slideshow.offsetHeight;
}

function swipe(e){
  e.preventDefault();
  e.stopPropagation();
  delta = e.touches[0].pageX - start_x;
  relative_delta = swipe_relative_scale * delta;
  delta *= swipe_scale;
  project_slideshow.style.transform = 'translateX(' + (project_slideshow.gm.current_slide.dataset.x * 1 + delta) + '%)';
}

function endSwipe(e){
  e.preventDefault();
  e.stopPropagation();
  project_slideshow.classList.remove('no_transition');
  project_slideshow.offsetHeight;
  
  if (Math.abs(relative_delta) < 30){
    project_slideshow.style.transform = 'translateX(' + project_slideshow.gm.current_slide.dataset.x + '%)';
    project_slideshow.offsetHeight;
  } else {
    if (relative_delta > 0){
      previous();
    } else {
      next();
    }
  }
}

function updatePosition(e){
  lock = true;
  project_slideshow.style.transform = 'translateX(' + project_slideshow.gm.current_slide.dataset.x + '%)';
}

function normalizePosition(e){
  if (e.propertyName != 'transform') return;

  if (project_slideshow.gm.current < 2){
    setNormalizedPosition(project_slideshow.gm.n - 4 + project_slideshow.gm.current);
    return;
  }
  if (project_slideshow.gm.current > project_slideshow.gm.n - 3){
    setNormalizedPosition(4 - project_slideshow.gm.n + project_slideshow.gm.current);
    return;
  }
  lock = false;
}

function setNormalizedPosition(normalized_position){
  lock = true;
  project_slideshow.classList.add('no_transition');
  project_slideshow.offsetHeight;
  setTimeout(function(){ finishNormalization(normalized_position) }, 5);
}

function finishNormalization(normalized_position){
  project_slideshow.gm.current = normalized_position;
  project_slideshow.offsetHeight;
  project_slideshow.classList.remove('no_transition');
  lock = false;  
}

function documentKeyHandler(e){
  switch(e.keyCode){
    case 39:
      modalNext();
    break;
    case 37:
      modalPrevious();
    break;
    case 27:
      close();
    break;
  }
}

export function loadAsync(){
  document.querySelectorAll('.load_async').forEach((e) => {
    e.querySelector('img').setAttribute('src', e.dataset.url);
  });
}

export function openModalSlideshow(){
  if (window.innerWidth <= 767){
    return; 
  } 
  document.getElementById('modal_slideshow_container').classList.add('open');
  modal_slideshow.gm.current = this.dataset.slide;
}

export function close(){
  document.getElementById('modal_slideshow_container').classList.remove('open');
}

export function next(){
  if (lock) return;

  project_slideshow.gm.nextSlide();
}

export function previous(){
  if (lock) return;

  project_slideshow.gm.previousSlide();
}

function modalNext(){
  modal_slideshow.gm.nextSlide();
}

function modalPrevious(){
  modal_slideshow.gm.previousSlide();
}
